
.noBorderCol{
    border: 0 solid white;
}

.colorRed{
    color:darkred;
}
.statusIcon{
    width: 50px;
    height: 40px;
}
.borderTop{
    border-bottom: 1px solid lightblue;
    border-top: 1px solid lightblue;
}
.stRow{

}
.stRowCol{
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid lightgray;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.stRowLabel{
    color:gray;
}
.stRowTotal1{
    color: #222222;
    font-weight: bold;
    font-size: 16px;
}
.smallWorkLabel{
    font-size: 7px;
    padding-left: -10px;
}
.clickBtnCol{
    cursor: pointer;
    font-weight: bold;
}
.popupLabel{
    border-bottom: 1px solid lightblue;
    margin-bottom: 10px;
    height: 35px;
    align-items: center;
    align-content: center;
    display: flex;
    margin-right: 5px;
    color: #1890FF;
    text-align: left;
}

.deliveryLabel{
    color: #1890FF;
}
.numberServiceLabel{
    font-weight: bold;
}
.btnEmpty{
    margin: 10px;
    width: 93%;
    height: 50px;
}
.colRed{
    color: darkred;
    font-weight: bold;
}
.colRed2{
    color: white;
    font-weight: bold;
    background-color: darkred;
}
.weekRow{
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.borderBottom{
    border-bottom: 1px solid #1890FF;
}
.dtBgTh{
    background-color: gray;
    color: white;
    font-size: 16px;
    font-weight: bold;
}
.row1Th{
    border:1px solid lightgray;
}
.row1ThE{
    color:#1890FF;
    font-size: 11px;
}
.extHeader{
    font-size: 11px;
}