
.AnalysisOfAgedDebtorsTable{
    min-width: 99%;
    max-width: 99%;
    min-height: 210px;
    background-color: white;
    margin: 10px;
    padding: 10px;
    margin-top: 5px;
    border-right: 10px solid #F2F2F2;
}
.adTitle1{
    color: saddlebrown;
    font-weight: bold;
}
.adTitle2{
    color: lightsalmon;
    margin: 10px;
}
.adRowHeader{
    background-color: saddlebrown;
    color: white;

}
.adColTh{
    text-align: right;
    border-right: 1px solid white;
    padding: 5px;
}
.adColTd{
    text-align: right;
    font-size: 11px;
    border-right: 1px solid white;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.adPushLeft{
    text-align: left;
    justify-content: flex-start;
}
.adPushCenter{
    text-align: center;
    justify-content: center;
}

.circle-background,
.circle-progress {
    fill: none;
}

.circle-background {
    stroke: #ddd;
}

.circle-progress {
    stroke: red;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.circle-text {
    font-size: 3em;
    font-weight: bold;
    fill: red;
}

.diacatContainer{
    min-width: 99%;
    max-width: 99%;
    min-height: 300px;
    max-height: 300px;
    background-color: white;
    margin: 10px;
    padding: 10px;
}
.diacatContainer2{
    min-width: 99%;
    max-width: 99%;
    min-height: 180px;
    max-height: 180px;
    background-color: white;
    margin: 10px;
    padding: 10px;
}
.diacatLabelBox{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.diacatLabelCat{
    font-weight: bold;
    font-size: 12px;
}
.diacatLabelAmount{
    font-weight: bold;
    font-size: 12px;
}
.diacatPie{
    width: 75%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    max-height: 150px;
    min-height: 150px;
    align-self: center;
    margin-left: 25px;
    margin-top: 30px;
}
.diacatPie2{
    width: 75%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    max-height: 120px;
    min-height: 120px;
    align-self: center;
    margin-left: 25px;
}

.highchartsBox{
    height: 300px;
    min-height: 300px;
    max-height: 300px;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 10px;
    max-width: 100%;
}
.highchartsBox2{
    height: 300px;
    margin: 10px;
    max-width: 100%;
    min-width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.titleChart{
    color:red;
}


.AnalysisOfAgedDebtors{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 99%;
    max-width: 99%;
    min-height: 35px;
    max-height: 35px;
    margin-top: 5px;
    background-color: white;
    font-size: 25px;
    padding-left: 10px;
}

.spiPeriodBox{
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 99%;
    max-width: 99%;
    min-height: 90px;
    max-height: 90px;
    margin-top: 5px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.spiPeriodContentBox{
    min-width: 45px;
    max-width: 45px;
    min-height: 20px;
    max-height: 20px;
}
.spiPeriodContentBoxLabel{
    color:gray;
    font-weight: bold;
}
.spiPeriodContentBoxButton{
    min-width: 45px;
    max-width: 45px;
    min-height: 20px;
    max-height: 20px;
    background-color: lightgrey;
    border-right: 1px solid white;
    cursor: pointer;
}
.spiPeriodContentBoxButton2{
    /*min-width: 45px;
    max-width: 45px;*/
    min-height: 20px;
    max-height: 20px;
    background-color: lightgrey;
    border-right: 1px solid white;
    cursor: pointer;
}



.spiYearBox{
    text-align: left;
    color:darkred;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 99%;
    max-width: 99%;
    min-height: 40px;
    max-height: 40px;
    margin-top: 10px;
    font-size: 18px;
    border-bottom: 1px solid gray;
}
.datePicker{
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.spiContainer{
    margin: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 99%;
    max-width: 99%;
    min-height: 172px;
    max-height: 172px;
    background-color: white;
    padding: 10px;
}
.spiInvoiceDate{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 35px;
    min-height: 35px;
    min-width: 100%;
    max-width: 100%;
    font-weight: bold;
    border-bottom: 1px solid gray;
}
.spiInvoiceDateLabel{
    min-width: 40%;
    max-width: 40%;
    max-height: 70%;
    text-align: left;
}
.spiInvoiceDateSwitch{
    min-width: 70%;
    max-height: 30%;
    text-align: left;
}



.tbdContainer{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 10px;
}
.diacatContainerTitle{
    text-align: center;
    color: #01B1A8;
    margin-bottom: 5px;

}
.tbdTitle{
    text-align: center;
    color: #01B1A8;
}
.tbdAmount{
    text-align: right;
    font-size: 18px;
}


.tisContainer{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 100%;
    min-height: 192px;
    max-height: 192px;
    background-color: #01B1A8;
    border-radius: 70%;
    color:white;
    padding: 10px;
}
.tisIcon{
    font-size: 32px;
}
.tisAmount{
    font-size: 45px;
    font-weight: bold;
}
.tisFooter{

}
.dashPointer{
    cursor: pointer;
}
.dashPaidContainer{
    background-color: white;
    padding: 0px;
    mso-ignore: padding;
    color: black;
    font-size: 11.0pt;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    font-family: Calibri, sans-serif;
    mso-font-charset: 0;
    mso-number-format: General;
    text-align: general;
    vertical-align: bottom;
    border: none;
    mso-background-source: auto;
    mso-pattern: auto;
    mso-protection: locked visible;
    white-space: nowrap;
    mso-rotate: 0;

    margin: 10px;
    margin-bottom: 0px;
}
.dashPaidIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    min-height: 58px;
    max-height: 58px;
}
.dashPaidAmount{
    color: black;
    font-size: 11.0pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    text-align: general;
    white-space: nowrap;

    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    max-height: 100px;
}
.dashPaidFooterText{
    color:#01B1A8;
    font-size: 10.0pt;
    font-weight: 700;
    vertical-align: middle;

    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    min-height: 35px;
    max-height: 35px;

    border-top-style: solid;
    border-top-width: 2px;
    border-top-color: #F2F2F2;
}
.PaidIcon1{
    color: black;
    font-size: 11.0pt;
    font-weight: 400;
    font-style: normal;
    font-family: Calibri, sans-serif;
    text-align: general;
    white-space: nowrap;
    max-height: 57px;
    min-height: 57px;
    max-width: 57px;
    min-width: 57px;
}
.dd{
    color:saddlebrown;
}