

table, tr, th {
    border-collapse: collapse;
    collapse: true;
}

table {
    min-width: 100%;
    max-width: 100%;
}

.thAlignCenter {
    text-align: center;
}

td.thNoBorder {
    border: none;
}

th.thNoBorder {
    border: none;
}

tr.trBorder {
    border: 1px solid black;
}

.container {
    width: 90%;
    margin: 20px auto 0;
}

.secondTable {
    margin-top: 75px;
}

.spaceMe {
    padding-left: 10px;
}

.putLeft {
    text-align: left;
}

.perFive {
    min-width: 50%;
}

.pushCenter {
    text-align: center;
}
.pullLeft{
    align-self: first;
    margin-left: 10px;
}
.pullRight{
    align-self: last;
    margin-right: 10px;
}
.addressContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
}
.descriptionItem{
    min-width: 50%;
    text-align: left;
    padding-left: 10px;
}
.pushRight{
    text-align: right;
}
.descriptionItemDetail{
    padding-left: 10px;
}
.amountItemDetail{
    padding-right: 10px;
}
.noBorderColumn{
    border-left-width: 0;
    border-right-width: 0;
}
.borderColumn{
    border-left-width: 1px;
    border-right-width: 1px;
    border-left-style: solid;
}
.grossEarningTh{
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: black;
}
.thNoBorder{
    border-width:0;
}
.trBorder{
    border: 1px solid black;
}
.equalColumn{
    width: 50%;
}
.removeLeftBorder{
    border-left-width: 0;
}
.increaseBy50{
    height: 100px;
    border-top-width: 1px;
    border-top-style: solid;
}
.trBorderBottom{
    border-bottom: 1px solid black;
}
