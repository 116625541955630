.App {
    font-family: sans-serif;
    text-align: center;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}
.selectedPopup{
    font-weight: bold;
    color: #FF6359;
    font-size: 16px;
}
.pPop{
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: lightgrey;
}
.listDetail{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
}
.listTitle{
    font-weight: bold;
    font-size: 16px;
    color: #096dd9;
}