
.spiPeriodBox{
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 99%;
    max-width: 99%;
    min-height: 90px;
    max-height: 90px;
    margin-top: 5px;
    margin-left: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.spiPeriodContentBox{
    min-width: 45px;
    max-width: 45px;
    min-height: 20px;
    max-height: 20px;
}
.spiPeriodContentBoxLabel{
    color:gray;
    font-weight: bold;
    cursor: pointer;
}
.spiPeriodContentBoxButton{
    min-width: 45px;
    max-width: 45px;
    min-height: 20px;
    max-height: 20px;
    background-color: lightgrey;
    border-right: 1px solid white;
    cursor: pointer;
}



.spiYearBox{
    text-align: left;
    color:darkred;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 99%;
    max-width: 99%;
    min-height: 90px;
    max-height: 90px;
    margin-top: 15px;
    font-size: 18px;
    justify-content: space-between;
}

.spiContainer{
    margin: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 99%;
    max-width: 99%;
    min-height: 192px;
    max-height: 192px;
    background-color: white;
    padding: 10px;
}
.spiInvoiceDate{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 35px;
    min-height: 35px;
    min-width: 100%;
    max-width: 100%;
    font-weight: bold;
    border-bottom: 1px solid gray;
}
.spiInvoiceDateLabel{
    min-width: 30%;
    max-width: 30%;
    max-height: 70%;
    text-align: left;
    color:#FF5D59;
}
.spiInvoiceDateSwitch{
    min-width: 50%;
    max-height: 30%;
    text-align: right;
}

