

.wrapperContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 80%;
    min-height: 80%;
    background-color: #EFF6FB;
    border-top-width: 2px;
    border-top-style: solid;
    border-color: black;
    padding: 10px;
}