

.dashboardContainer{
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1.0pt solid #333F4F;
    background: #F2F2F2;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}
.errorSelectCompany{
    margin: 10px;
    padding: 10px;
    color:red;
}